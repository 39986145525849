import Layout from '../components/layout'
import { flashState } from '../state/atoms'
import { useSetRecoilState } from 'recoil'
import { useState } from 'react'
import { StyledSubmitButton, TextInput, Container } from '../styles/shared.css'
import styled from 'styled-components'
import api from '../lib/api'

const LoginContainer = styled(Container)`
  margin: 100px auto;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 25px;
`

const Label = styled.label`
  display: block;
  font-size: 20px;
  margin: 0 0 12px;
`

export default function Login() {
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const setFlash = useSetRecoilState(flashState)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    const request = await api('login/request', { email })
    if (request.ok) {
      setFlash(`<em>We sent a magic sign-in link to ${email}!</em> Try again if you didn't receive it.`)
    }
    setIsSubmitting(false)
  }

  return <>
    <LoginContainer>
      <form onSubmit={handleSubmit}>
        <Label htmlFor="email">Email address:</Label>
        <StyledTextInput type='email'
          name='email'
          placeholder='name@domain.com'
          autoComplete='email'
          className='autofocus'
          onFocus={e => e.currentTarget.select()}
          onChange={e => setEmail(e.target.value)}
        />
        <StyledSubmitButton
          disabled={isSubmitting}
          type='submit'
          value={isSubmitting ? 'Loading...' : 'Login →'}
          onClick={handleSubmit} />
      </form >
    </LoginContainer>
  </>
}

Login.getLayout = function getLayout(page) {
  return <Layout container={LoginContainer}>{page}</Layout>
}